<template>
  <div class="sculptor paddler-steppers">
      <v-col cols="12">
        <validation-provider ref="allValidation" name="unit name" rules="required" v-slot="{ classes, errors }">
          <label class="text-headings">Unit</label>
          <v-text-field class="mt-4" hide-details v-if="updateMode"
                        solo v-model="paddlerData.departmentAssigned.unitName" disabled>
          </v-text-field>
          <v-combobox v-else class="mt-4" :items="units" hide-details placeholder="Select Unit" item-text="name" item-value="id"  solo v-model="paddlerData.unit">
            <template #append>
              <icons name="select-dropdown" />
            </template>
          </v-combobox>
          <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </v-col>
      <v-col cols="12">
        <validation-provider ref="allValidation" name="department name" rules="required" v-slot="{ classes, errors }">
          <label class="text-headings">Departments</label>
          <v-text-field class="mt-4"  v-if="updateMode" v-model="paddlerData.departmentAssigned.name"
                        hide-details solo disabled>
          </v-text-field>
          <v-combobox class="mt-4" v-else :items="departments" v-model="paddlerData.departmentId" hide-details
            placeholder="Select department" item-text="name" item-value="id" solo>
            <template #append>
              <icons name="select-dropdown" />
            </template>
          </v-combobox>
          <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </v-col>
      <v-col cols="12">
        <validation-provider ref="allValidation" name="role" rules="required" v-slot="{ classes, errors }">
          <label class="text-headings">Role</label>
          <v-combobox class="mt-4" :items="roles" hide-details placeholder="Select role" solo
            v-model="paddlerData.jobRole">
            <template #append>
              <icons name="select-dropdown" />
            </template>
          </v-combobox>
          <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </v-col>
      <v-col cols="12">
        <validation-provider ref="allValidation" name="level" rules="required" v-slot="{ classes, errors }">
          <label class="text-headings">Level</label>
          <v-combobox class="mt-4" :items="levels" hide-details placeholder="Select experience level" solo
            v-model="paddlerData.employeeLevel">
            <template #append>
              <icons name="select-dropdown" />
            </template>
          </v-combobox>
          <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </v-col>
  </div>
</template>

<script>
  import Icons from "../../../components/reuseables/Icons.vue";
  import {
    mapGetters
  } from "vuex";
import { getAllUnits } from "../../../services/api/APIService";
  export default {
    components: {
      Icons
    },
    name: "Step1",
    props:{
      updateMode: {
      type: Boolean,
      required: true,
    },
    },
    data() {
      return {
        units:[],
        departments: [],
        roles: [
          "Account Officer",
          "Backend",
          "Fullstack",
          "Software Engineer",
          "Frontend Engineer",
          "UI/UX Designer",
          "Project Manager",
          "DevOps Engineer",
          "Database Administration",
          "Legal"
        ],
        levels: [
          "Intern", "Executive Trainee", "Assistant Officer", "Deputy Officer",
          "Officer", "Senior Officer", "Assistant Manager", "Deputy Manager", "Manager",
           "Deputy Senior Manager", "Senior Manager", "Assistant General Manager", "Deputy General Manager",
          "General Manager 2", "General Manager 1", "Executive Director 2",
           "Executive Director 1", "C - Level 3", "C - Level 2", "C - Level 1"
        ],
      };
    },
    computed: {
      ...mapGetters("paddler", ["paddlerData"]),
    },
    watch: {
      "paddlerData.unit": {
        handler: function (val) {
          if (val) {
            this.departments = val.departments;
          }
        },
        immediate: true,
        deep: true
      }
    },
    methods:{
      async getAllUnits() {
        await getAllUnits().then(res => {
          this.units = res.data
        }).catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
        })
      },
    },
    async created() {
      this.getAllUnits()
      console.log(this.paddlerData)
        }
  };
</script>

<style lang="scss">
  .text-headings {
    color: #4f4f4f;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .paddler-steppers .v-text-field input {
    color: #c4c4c4;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
</style>