import { render, staticRenderFns } from "./Step4.vue?vue&type=template&id=25153d0f&scoped=true&"
import script from "./Step4.vue?vue&type=script&lang=js&"
export * from "./Step4.vue?vue&type=script&lang=js&"
import style0 from "./Step4.vue?vue&type=style&index=0&id=25153d0f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25153d0f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCol,VCombobox,VSelect})
