<template>
  <div class="paddler-steppers">
      <div class="tw-flex tw-flex-wrap">
        <v-col md="6" cols="12">
          <validation-provider name="paddler email" rules="required" v-slot="{ classes, errors }">
            <label class="text-headings">Email</label>
            <v-text-field class="mt-4" label="Paddler e-mail" hide-details v-model="paddlerData.email" solo type="email"
              prepend-inner-icon="mdi-email-outline" color="#004aad">
            </v-text-field>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>

        </v-col>
        <v-col md="6" cols="12">
          <label class="text-headings">Phone Number</label>
          <div class="mt-4">
            <phone-number :phone-number-exist="paddlerData.phoneNumber" @getNumber="getNumber" />
          </div>
        </v-col>
      </div>
      <div class="tw-flex tw-flex-wrap">
        <v-col md="6" cols="12">
          <validation-provider name="NIN" rules="required" v-slot="{ classes, errors }">
            <label class="text-headings">NIN</label>
            <v-text-field class="mt-4" placeholder="09000000000" hide-details solo type="Number"
              v-model="paddlerData.nationalIdentificationNumber"></v-text-field>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>

        </v-col>
        <v-col md="6" cols="12" class="tw-hidden">
          <label class="text-headings">Staff ID</label>
          <v-text-field class="mt-4" label="Create staff ID" hide-details outlined dense disabled>
            <template #prepend-inner>
              <icons name="generate" />
            </template>
          </v-text-field>
        </v-col>
      </div>
      <div class="grey-sp-illusion"></div>
      <div class="tw-flex tw-flex-wrap">
        <v-col cols="12" md="6">
          <validation-provider name="country" rules="required" v-slot="{ classes, errors }">
            <label class="text-headings">Country of Residence</label>
            <v-combobox class="mt-4" :items="countries" hide-details placeholder="Nigeria" solo item-text="name"
              item-value="name" @change="getState" v-model="paddlerData.addressOfEmployee.country">
              <template #append>
                <icons name="select-dropdown" />
              </template>
            </v-combobox>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>

        </v-col>
        <v-col cols="12" md="6">
          <validation-provider name="state" rules="required" v-slot="{ classes, errors }">

            <label class="text-headings">State of Residence</label>
            <v-combobox class="mt-4" :items="states" hide-details placeholder="Lagos" item-text="name" item-value="name"
              solo @change="getCity" v-model="paddlerData.addressOfEmployee.state">
              <template #append>
                <icons name="select-dropdown" />
              </template>
            </v-combobox>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>

        </v-col>
      </div>
      <div class="tw-flex tw-flex-wrap">
        <v-col cols="12" md="6">
          <validation-provider name="city" rules="required" v-slot="{ classes, errors }">

            <label class="text-headings">City of Residence</label>
            <v-combobox class="mt-4" :items="cities" hide-details placeholder="Lekki" solo
              v-model="paddlerData.addressOfEmployee.city">
              <template #append>
                <icons name="select-dropdown" />
              </template>
            </v-combobox>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>

        </v-col>
        <v-col cols="12" md="6">
          <label class="text-headings">Street Name</label>
          <v-text-field class="mt-4" placeholder="William Street" hide-details solo
            v-model="paddlerData.addressOfEmployee.streetName"></v-text-field>
        </v-col>
      </div>
      <div class="tw-flex tw-flex-wrap">
        <v-col cols="12" md="6">
          <label class="text-headings">House Number.</label>
          <v-text-field class="mt-4" placeholder="7" hide-details type="Number" solo
            v-model="paddlerData.addressOfEmployee.houseNumber"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="tw-hidden">
          <label class="text-headings">Apartment</label>
          <v-text-field class="mt-4" placeholder="5" hide-details outlined dense></v-text-field>
        </v-col>
      </div>
  </div>
</template>

<script>
  import Icons from "../../../components/reuseables/Icons.vue";
  import {
    mapGetters
  } from "vuex";
  import PhoneNumber from "@/components/reuseables/PhoneNumber.vue";
  import {
    getAllCitiesInAState,
    getAllCountries,
    getAllStatesByCountry
  } from "@/services/api/APIService";
  import Lga from "@/assets/lga";
  export default {
    components: {
      PhoneNumber,
      Icons
    },
    props: {

    },
    name: "Step3",
    data() {
      return {
        countries: [],
        states: [],
        cities: [],
        getCountriesLoading: false,
        getStatesLoading: false,
        getCitiesLoading: false
      }
    },
    computed: {
      ...mapGetters("paddler", ['paddlerData'])
    },
    methods: {
      async getCountries() {
        try {
          this.getCountriesLoading = true;
          const response = await getAllCountries();
          this.countries = response.data.data;
          this.getCountriesLoading = false;
        } catch (err) {
          console.log(err);
          this.getCountries = false;
        }
      },
      async getState() {
        try {
          this.getStatesLoading = true;
          let data = {
            country: this.paddlerData.addressOfEmployee.country.name,
          };
          const response = await getAllStatesByCountry(data);
          this.states = response.data.data.states;
          if (this.paddlerData.addressOfEmployee.country.name.toLowerCase() === "nigeria") {
            this.states.push({
              name: "Rivers State"
            });
          }
          this.states.forEach(state => state.name === 'Lagos State' ? state.name = 'Lagos' : state.name)
          this.getStatesLoading = false;
        } catch (err) {
          console.log(err.response);
        }
      },
      async getCity() {
        if (this.paddlerData.addressOfEmployee.country.name.toLowerCase() === "nigeria") {
          this.cities = Lga[this.paddlerData.addressOfEmployee.state.name];
        } else {
          try {
            this.getCitiesLoading = true;
            let data = {
              country: this.paddlerData.addressOfEmployee.country.name,
              state: this.paddlerData.addressOfEmployee.state.name,
            };
            const response = await getAllCitiesInAState(data);
            this.cities = response.data.data;
            this.getCitiesLoading = false;
          } catch (err) {
            console.log(err.response);
            this.cities = [];
            this.getCitiesLoading = false;
          }
        }
      },
      getNumber(number) {
        this.paddlerData.phoneNumber = number;
      },
    },
    async created() {
      await this.getCountries()
    }
  };
</script>

<style lang="scss" scoped>
  .grey-sp-illusion {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: #f6f7f9;
    margin: 40px 0;

    @media (max-width: 768px) {
      height: 20px;
      margin: 20px 0;
    }
  }
</style>