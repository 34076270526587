<template>
  <div>
    <div class="tw-flex tw-flex-wrap">
            <v-col md="6" cols="12">
                <label class="text-headings">Next of Kin - First Name</label>
                <v-text-field class="mt-4"
                     placeholder="Zoe" hide-details solo
                     v-model="paddlerData.firstnameOfNextOfKin"
                 ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
                <label class="text-headings">Next of Kin - Last Name</label>
                <v-text-field class="mt-4" placeholder="Davids"
                              hide-details solo
                              v-model="paddlerData.lastnameOfNextOfKin"></v-text-field>
            </v-col>
        </div>
        <div class="tw-flex tw-flex-wrap">
            <v-col md="6" cols="12">
                <label class="text-headings">Next of Kin - Email</label>
                <v-text-field class="mt-4" placeholder="leila@gnailili.com" hide-details solo
                prepend-inner-icon="mdi-email-outline"  type="email" v-model="paddlerData.emailOfNextOfKin"></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
                <label class="text-headings">Next of Kin - Phone Number</label>
              <div class="mt-4">
                <phone-number :phone-number-exist="paddlerData.phoneNumberOfNextOfKin"
                              @getNumber="getNumber"/>
              </div>
            </v-col>
        </div>
  </div>
</template>

<script>
import PhoneNumber from "@/components/reuseables/PhoneNumber.vue";
import {mapGetters} from "vuex";
// import {getKudaAuthToken} from "@/services/api/APIService";

export default {
name:"Step5",
  components: {PhoneNumber},
  computed:{
   ...mapGetters("paddler",["paddlerData"])
  },
  methods:{
    getNumber(number) {
      this.paddlerData.phoneNumberOfNextOfKin = number;
    },
  },
  async created() {

}
}
</script>

<style>

</style>