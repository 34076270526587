<template>
  <div class="paddler-steppers">
    <div class="tw-flex tw-flex-wrap">
      <v-col md="6" cols="12">
        <label class="text-headings">Profile Photo</label>
        <div class="tw-flex tw-justify-center tw-flex-col mt-4">
          <button class="button-logo-input" type="button" @click="onProfileFile()" :class="image ? 'border-0' : ''">
            <v-progress-circular :size="100" :width="3" color="blue" indeterminate style="position: absolute"
              v-if="profileImageLoading"></v-progress-circular>

            <img :src="image" alt="" class="image-label" v-if="profileImage" />
            <label class="image-label" v-else>
              <img src="@/assets/ion_image-outline.png" alt="" class="" />
            </label>

          </button>
          <input type="file" ref="profileImage" accept="image/*" style="display: none" @change="onProfileImagePicked"
            class="image-input" />
        </div>
      </v-col>
      <v-col md="6" cols="12">
        <label class="text-headings">Upload documents</label>
        <div class="tw-flex tw-justify-center tw-flex-col mt-4">
          <button class="button-logo-input" type="button" @click="onDocFile()" :class="docFile ? 'border-0' : ''">
            <v-progress-circular :size="100" :width="3" color="blue" indeterminate style="position: absolute"
              v-if="docFileLoading"></v-progress-circular>

            <img :src="docFile" alt="" class="image-label" v-if="paddlerResource" />
            <label class="image-label upload-doc" for="file" v-else>
              <icons name="upload-doc" />
            </label>
          </button>
          <input type="file" ref="docFile" accept="image/*,.doc,.docx,.pdf" style="display: none"
            @change="onDocumentPicked" class="image-input" />
        </div>
      </v-col>
    </div>
    <div class="tw-flex tw-flex-wrap">
      <v-col md="6" cols="12">
        <validation-provider name="first name" rules="required" v-slot="{ classes, errors }">
        <label class="text-headings">First Name</label>
        <v-text-field class="" placeholder="Zoe" hide-details solo v-model="paddlerData.firstname"></v-text-field>
        <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </v-col>
      <v-col md="6" cols="12">
        <validation-provider name="last name" rules="required" v-slot="{ classes, errors }">
        <label class="text-headings">Last Name</label>
        <v-text-field class="" placeholder="Davids" hide-details solo v-model="paddlerData.lastname"></v-text-field>
        <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </v-col>
    </div>
    <div class="tw-flex tw-flex-wrap">
      <v-col cols="12" md="6">
        <validation-provider name="gender" rules="required" v-slot="{ classes, errors }">
        <label class="text-headings">Gender</label>
        <v-select class="" :items="genders" hide-details placeholder="Female" solo v-model="paddlerData.gender">
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-select>
        <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="6">
        <validation-provider name="marital status" rules="required" v-slot="{ classes, errors }">

        <label class="text-headings">Marital Status</label>
        <v-select class="" :items="maritalStatuses" hide-details placeholder="Single"
          v-model="paddlerData.maritalStatus" solo>
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-select>
        <span class="error-text">{{errors[0]}}</span>
        </validation-provider>

      </v-col>
    </div>
    <div class="tw-flex tw-flex-wrap">
      <v-col cols="12" md="6">
        <validation-provider name="date of birth" rules="required" v-slot="{ classes, errors }">
        <label class="text-headings">Date of Birth</label>
        <v-menu ref="dobMenu" v-model="dobMenu" :close-on-content-click="false"
          :return-value.sync="paddlerData.dateOfBirth" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="paddlerData.dateOfBirth" solo prepend-inner-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on" placeholder="1980-03-10" hide-details color="#004aad"></v-text-field>
          </template>
          <v-date-picker v-model="paddlerData.dateOfBirth" :max="maxDate" no-title scrollable color="#004aad">
            <v-spacer></v-spacer>
            <v-btn text color="#004aad" @click="dobMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="#004aad" @click="$refs.dobMenu.save(paddlerData.dateOfBirth)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="6">
        <validation-provider name="employment date" rules="required" v-slot="{ classes, errors }">
        <label class="text-headings">Employment Date</label>
        <v-menu ref="employmentMenu" v-model="epmtMenu" :close-on-content-click="false"
          :return-value.sync="paddlerData.dateOfEmployment" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="paddlerData.dateOfEmployment" solo prepend-inner-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on" placeholder="2022-01-01" hide-details color="#004aad"></v-text-field>
          </template>
          <v-date-picker v-model="paddlerData.dateOfEmployment" no-title scrollable :max="maxDate" color="#004aad">
            <v-spacer></v-spacer>
            <v-btn text color="#004aad" @click="employmentMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="#004aad" @click="$refs.employmentMenu.save(paddlerData.dateOfEmployment)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <span class="error-text">{{errors[0]}}</span>
        </validation-provider>
      </v-col>
    </div>
  </div>
</template>

<script>
  import Icons from "../../../components/reuseables/Icons.vue";
  import {
    uploadToCloudinary
  } from "@/services/api/APIService";
  import {
    mapGetters
  } from "vuex";
  export default {
    components: {
      Icons
    },
    name: "Step2",
    props: {

    },
    data() {
      return {
        maritalStatuses: ["SINGLE", "ENGAGED", "DIVORCED", "MARRIED"],
        genders: ["MALE", "FEMALE"],
        dobDate: "",
        maxDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        dobMenu: false,
        employmentDate: "",
        minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        epmtMenu: false,
        profileImage: null,
        image: null,
        profileImageLoading: false,
        docFile: null,
        paddlerResource: null,
        docFileLoading: false
      };
    },
    computed: {
      preset() {
        return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
      },
      ...mapGetters("paddler", ['paddlerData'])
    },
    methods: {
      onProfileFile() {
        this.$refs.profileImage.click();
      },
      onDocFile() {
        this.$refs.docFile.click();
      },
      onProfileImagePicked(event) {
        const files = event.target.files[0];
        try {
          this.profileImageLoading = true;
          const fileReader = new FileReader();
          fileReader.addEventListener("load", async () => {
            this.recoveredImg = "";
            this.image = fileReader.result;
            this.profileImage = files.name;
            let newData = {
              file: this.image,
              upload_preset: this.preset
            };
            const response = await uploadToCloudinary(newData);
            this.profileImage = response.data.url;
            this.paddlerData.profileImage = response.data.url
            this.profileImageLoading = false;
          });
          fileReader.readAsDataURL(files);
        } catch (err) {
          console.log(err.response);
        }
      },
      onDocumentPicked(event) {
        const files = event.target.files[0];
        try {
          this.docFileLoading = true;
          const fileReader = new FileReader();
          fileReader.addEventListener("load", async () => {
            this.recoveredImg = "";
            this.docFile = fileReader.result;
            this.paddlerResource = files.name;
            let newData = {
              file: this.docFile,
              upload_preset: this.preset
            };
            const response = await uploadToCloudinary(newData);
            console.log(response);
            this.paddlerResource = response.data.url;
            this.docFileLoading = false;
          });
          fileReader.readAsDataURL(files);
        } catch (err) {
          console.log(err.response);
        }
      }
    },
    created() {
      if (this.paddlerData.profileImage) {
        this.profileImage = this.paddlerData.profileImage
        this.image = this.paddlerData.profileImage
      }
    }
  };
</script>

<style lang="scss" scoped>
  .text-headings {
    color: #4f4f4f;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .image-input {
    display: none;
  }

  .image {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;

    @media screen and (max-width: 375px) {
      margin-top: 40%;
      margin-left: 40%;
    }
  }

  .image-label .image {
    width: 100%;
    height: 192px;
    border-radius: 10px;
    object-fit: cover;
  }

  .image-label .image[src] {
    max-width: 100%;
    max-height: 300px;
    display: block;
    margin: auto;
    border-radius: 10px;
  }

  .image-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 192px;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
    border: 2px dashed #004aad;
    cursor: pointer;

    @media screen and (max-width: 375px) {
      height: 258px;
      border-radius: 10px;
      border: 1px dashed #004aad;
      margin-bottom: 40px;
    }
  }

  .upload-doc {
    border: 2px dashed rgba(189, 189, 189, 0.5);
  }
</style>