var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paddler-steppers"},[_c('div',{staticClass:"tw-flex tw-flex-wrap"},[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"paddler email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"text-headings"},[_vm._v("Email")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Paddler e-mail","hide-details":"","solo":"","type":"email","prepend-inner-icon":"mdi-email-outline","color":"#004aad"},model:{value:(_vm.paddlerData.email),callback:function ($$v) {_vm.$set(_vm.paddlerData, "email", $$v)},expression:"paddlerData.email"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('label',{staticClass:"text-headings"},[_vm._v("Phone Number")]),_c('div',{staticClass:"mt-4"},[_c('phone-number',{attrs:{"phone-number-exist":_vm.paddlerData.phoneNumber},on:{"getNumber":_vm.getNumber}})],1)])],1),_c('div',{staticClass:"tw-flex tw-flex-wrap"},[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"NIN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"text-headings"},[_vm._v("NIN")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"placeholder":"09000000000","hide-details":"","solo":"","type":"Number"},model:{value:(_vm.paddlerData.nationalIdentificationNumber),callback:function ($$v) {_vm.$set(_vm.paddlerData, "nationalIdentificationNumber", $$v)},expression:"paddlerData.nationalIdentificationNumber"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('v-col',{staticClass:"tw-hidden",attrs:{"md":"6","cols":"12"}},[_c('label',{staticClass:"text-headings"},[_vm._v("Staff ID")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Create staff ID","hide-details":"","outlined":"","dense":"","disabled":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('icons',{attrs:{"name":"generate"}})]},proxy:true}])})],1)],1),_c('div',{staticClass:"grey-sp-illusion"}),_c('div',{staticClass:"tw-flex tw-flex-wrap"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"text-headings"},[_vm._v("Country of Residence")]),_c('v-combobox',{staticClass:"mt-4",attrs:{"items":_vm.countries,"hide-details":"","placeholder":"Nigeria","solo":"","item-text":"name","item-value":"name"},on:{"change":_vm.getState},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icons',{attrs:{"name":"select-dropdown"}})]},proxy:true}],null,true),model:{value:(_vm.paddlerData.addressOfEmployee.country),callback:function ($$v) {_vm.$set(_vm.paddlerData.addressOfEmployee, "country", $$v)},expression:"paddlerData.addressOfEmployee.country"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"text-headings"},[_vm._v("State of Residence")]),_c('v-combobox',{staticClass:"mt-4",attrs:{"items":_vm.states,"hide-details":"","placeholder":"Lagos","item-text":"name","item-value":"name","solo":""},on:{"change":_vm.getCity},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icons',{attrs:{"name":"select-dropdown"}})]},proxy:true}],null,true),model:{value:(_vm.paddlerData.addressOfEmployee.state),callback:function ($$v) {_vm.$set(_vm.paddlerData.addressOfEmployee, "state", $$v)},expression:"paddlerData.addressOfEmployee.state"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"tw-flex tw-flex-wrap"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"text-headings"},[_vm._v("City of Residence")]),_c('v-combobox',{staticClass:"mt-4",attrs:{"items":_vm.cities,"hide-details":"","placeholder":"Lekki","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icons',{attrs:{"name":"select-dropdown"}})]},proxy:true}],null,true),model:{value:(_vm.paddlerData.addressOfEmployee.city),callback:function ($$v) {_vm.$set(_vm.paddlerData.addressOfEmployee, "city", $$v)},expression:"paddlerData.addressOfEmployee.city"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"text-headings"},[_vm._v("Street Name")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"placeholder":"William Street","hide-details":"","solo":""},model:{value:(_vm.paddlerData.addressOfEmployee.streetName),callback:function ($$v) {_vm.$set(_vm.paddlerData.addressOfEmployee, "streetName", $$v)},expression:"paddlerData.addressOfEmployee.streetName"}})],1)],1),_c('div',{staticClass:"tw-flex tw-flex-wrap"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"text-headings"},[_vm._v("House Number.")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"placeholder":"7","hide-details":"","type":"Number","solo":""},model:{value:(_vm.paddlerData.addressOfEmployee.houseNumber),callback:function ($$v) {_vm.$set(_vm.paddlerData.addressOfEmployee, "houseNumber", $$v)},expression:"paddlerData.addressOfEmployee.houseNumber"}})],1),_c('v-col',{staticClass:"tw-hidden",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"text-headings"},[_vm._v("Apartment")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"placeholder":"5","hide-details":"","outlined":"","dense":""}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }