<template>
  <div class="paddler-steppers">
    <div class="tw-flex tw-flex-wrap">
      <v-col cols="12" md="6">
        <label class="text-headings">Weight</label>
        <v-combobox class="mt-4" :items="weights" hide-details placeholder="62kg" solo v-model="paddlerData.weight">
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" md="6">
        <label class="text-headings">Height</label>
        <v-combobox class="mt-4" :items="heights" hide-details placeholder="6FT" solo v-model="paddlerData.height">
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-combobox>
      </v-col>
    </div>
    <div class="tw-flex tw-flex-wrap">
      <v-col cols="12" md="6">
        <label class="text-headings">Blood Group</label>
        <v-select class="mt-4" :items="bloodGroups" hide-details placeholder="O+" solo v-model="paddlerData.bloodGroup">
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <label class="text-headings">Genotype</label>
        <v-select class="mt-4" :items="genotype" hide-details placeholder="AA" solo v-model="paddlerData.bloodType">
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-select>
      </v-col>
    </div>
    <div class="tw-flex tw-flex-wrap">
      <v-col md="6">
        <label class="text-headings"> Special Needs?</label>
        <v-select class="mt-4" placeholder="Dyslexic" :items="disabilities" hide-details solo
          v-model="paddlerData.specialNeeds">
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <label class="text-headings">Allergies</label>
        <v-select class="mt-4" :items="allergies" hide-details placeholder="Flowers" solo
          v-model="paddlerData.allergies">
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-select>
      </v-col>
    </div>
    <div class="tw-hidden tw-flex-wrap">
      <v-col cols="12" md="6">
        <label class="text-headings">Preferred HMO Hospital</label>
        <v-select class="mt-4" hide-details placeholder="Herbert Hospital" dense outlined>
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <label class="text-headings">Hospital Location</label>
        <v-select class="mt-4" hide-details placeholder="Yaba" dense outlined>
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-select>
      </v-col>
    </div>
  </div>
</template>

<script>
  import Icons from "../../../components/reuseables/Icons.vue";
  import {
    mapGetters
  } from "vuex";
  export default {
    components: {
      Icons
    },
    name: "Step4",
    props: {

    },
    data() {
      return {
        weights: ["20KG", "30KG", "40KG", "50KG", "60KG", "70KG", "80KG", "90KG", "100KG"],
        heights: ["2FT", "3FT", "4FT", "5FT", "5.1FT", "5.5FT", "6FT", "7FT"],
        genotype: ['OO', 'AO', 'BO', 'ABO', 'AA', 'BB', 'AS', 'SS'],
        allergies: ['Food allergies (e.g., peanuts, tree nuts, shellfish)',
          'Insect stings (e.g., bee stings)',
          'Medications (e.g., antibiotics like penicillin)',
          'Other'
        ],
        disabilities: ['Autism Spectrum Disorder (ASD)',
          'Attention-Deficit/Hyperactivity Disorder (ADHD)',
          'Intellectual Disabilities',
          'Learning Disabilities',
          'Speech and Language Disorders',
          'Visual Impairments',
          'Hearing Impairments',
          'Physical Disabilities',
          'Emotional and Behavioral Disorders',
          'Developmental Delay',
          'Traumatic Brain Injury (TBI)',
          'Other Health Impairments (e.g., Epilepsy, Asthma)',
          'Multiple Disabilities',
          'Deaf-Blindness',
          'Orthopedic Impairments',
          'Specific Learning Disability (SLD)',
          'Other'
        ],
        bloodGroups: ['O-', 'O+', 'A-', 'A+', 'B-', 'B+', 'AB-', 'AB+', 'A-', 'A+', 'B-', 'B+', 'Other']
      };
    },
    computed: {
      ...mapGetters("paddler", ["paddlerData"])
    },
  };
</script>

<style lang="scss" scoped>
  .text-headings {
    color: #4f4f4f;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .image-input {
    display: none;
  }

  .image {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;

    @media screen and (max-width: 375px) {
      margin-top: 40%;
      margin-left: 40%;
    }
  }

  .image-label .image {
    width: 100%;
    height: 192px;
    border-radius: 10px;
  }

  .image-label .image[src] {
    max-width: 100%;
    max-height: 300px;
    display: block;
    margin: auto;
    border-radius: 10px;
    object-fit: cover;
    object-position: top;
  }

  .image-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 192px;
    border-radius: 10px;
    border: 2px dashed #004aad;
    cursor: pointer;

    @media screen and (max-width: 375px) {
      height: 258px;
      border-radius: 10px;
      border: 1px dashed #004aad;
      margin-bottom: 40px;
    }
  }

  .upload-doc {
    border: 2px dashed rgba(189, 189, 189, 0.5);
  }
</style>