<template>
    <div >
        <div class="tw-flex tw-justify-center">
            <div class="page-sculptor">
                <div class="tw-flex mb-10 top-section">
                    <v-btn class="add-pd" @click="routeBack">
                        <icons name="back" class="mr-2" />
                        All Paddler</v-btn>
                    <v-spacer></v-spacer>
                    <div class="stepper">
                        <div class="step-number mb-2">
                            {{ currentStep }}/{{ totalSteps }}
                        </div>
                        <div class="dashes">
                            <div class="dash" :class="{ 'active-dash': index < currentStep }"
                                v-for="(dash, index) in totalSteps" :key="index"></div>
                        </div>
                    </div>
                </div>
                <div class="white-bg">
                    <validation-observer ref="form">
                        <Step1 v-if="currentStep === 1" :updateMode="updateMode" />
                        <Step2 v-else-if="currentStep === 2" />
                        <step-3 v-else-if="currentStep === 3" />
                        <step-4 v-else-if="currentStep === 4" />
                        <step-5 v-else-if="currentStep === 5" />
                        <step-6 v-else />
                    </validation-observer>
                </div>
                <div class="buttons">
                    <v-btn text class="ctrl-btn back" @click="previousStep" v-show="currentStep > 1">Back</v-btn>
                    <v-btn text class="ctrl-btn next ml-6" @click="handleButtonClick" :loading="loading">
                        {{ currentStep === totalSteps ? (updateMode ? "Update" : "Done") : "Next" }}
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icons from "../../components/reuseables/Icons.vue";
    import Step1 from "./AddPaddlerStepper/Step1.vue";
    import Step2 from "./AddPaddlerStepper/Step2.vue";
    import Step3 from "./AddPaddlerStepper/Step3.vue";
    import Step4 from "./AddPaddlerStepper/Step4.vue";
    import Step5 from "./AddPaddlerStepper/Step5.vue";
    import Step6 from "./AddPaddlerStepper/Step6.vue";
    import {
        mapGetters
    } from "vuex";
    import {
        addNewPaddler
    } from "@/services/api/APIService";
    import {
        updatePaddler
    } from '../../services/api/APIService';
    export default {
        components: {
            Icons,
            Step1,
            Step2,
            Step3,
            Step4,
            Step5,
            Step6
        },
        name: "AddPaddler",
        data() {
            return {
                totalSteps: 6,
                currentStep: 1,
                loading: false,
            };
        },
        computed: {
            ...mapGetters("paddler", ["paddlerData"]),
            updateMode() {
                return this.$store.state.paddler.updateMode;
            },

        },
        methods: {
            previousStep() {
                if (this.currentStep > 1) {
                    this.currentStep--;
                }
            },
            routeBack() {
            this.currentStep = 1
            this.$router.push({
                name: "AllPaddlers"
            })
        },
            async handleButtonClick() {
                if (this.currentStep < this.totalSteps) {
                    this.currentStep++;
                } else {
                    const isValid = await this.$refs.form.validate();
                    if (isValid) {
                        if (this.updateMode) {
                            await this.updatePaddler();
                        } else {
                            await this.savePaddler();
                        }
                    } else {
                        this.$displaySnackbar({
                            message: "Form validation failed. Please check the fields.",
                            success: false,
                        });
                    }
                }
            },

            async updatePaddler() {
                this.loading = true
                let data = this.paddlerData
                await updatePaddler(data).then(() => {
                        this.$displaySnackbar({
                            message: "Updated successfully",
                            success: true
                        })
                        this.$store.dispatch("paddler/setPaddlerData", {
                            address: {},
                            accountDetail: {}
                        })
                        sessionStorage.removeItem("currentPaddlerData")
                        this.loading = false
                        this.$router.push({
                            name: 'AllPaddlers'
                        })
                        this.currentStep = 1
                    }).catch(err => {
                        console.log(err)
                    })
                    .finally(() => this.loading = false)
            },

            async savePaddler() {
                this.loading = true
                if (this.paddlerData.departmentId.id){
                  this.paddlerData.departmentId = this.paddlerData.departmentId.id
                }
                if (this.paddlerData.unit.id) {
                    this.paddlerData.unit = this.paddlerData.unit.id
                }
                if (this.paddlerData.addressOfEmployee.country.name) {
                    this.paddlerData.addressOfEmployee.country = this.paddlerData.addressOfEmployee.country.name
                }
                if (this.paddlerData.addressOfEmployee.state.name) {
                    this.paddlerData.addressOfEmployee.state = this.paddlerData.addressOfEmployee.state.name
                }
                if (this.paddlerData.addressOfEmployee.city.name) {
                    this.paddlerData.addressOfEmployee.city = this.paddlerData.addressOfEmployee.city.name
                }
                if (this.paddlerData.accountDetail && this.paddlerData.accountDetail.bankName) {
                    this.paddlerData.accountDetail.bankCode = this.paddlerData.accountDetail.bankName.bankCode
                    this.paddlerData.accountDetail.bankName = this.paddlerData.accountDetail.bankName.bankName
                }
                let data = this.paddlerData
                await addNewPaddler(data).then(() => {
                        this.$displaySnackbar({
                            message: "Paddler added successfully",
                            success: true
                        })
                        this.$store.dispatch("paddler/setPaddlerData", {
                            address: {},
                            accountDetail: {}
                        })
                        this.loading = false
                        this.$router.push({
                            name: 'AllPaddlers'
                        })
                    }).catch(err => {
                        console.log(err)
                    })
                    .finally(() => this.loading = false)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .add-pd {
        color: #263238;
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: none;
        letter-spacing: 0;

        @media (max-width: 768px) {
            justify-content: start;
            margin-top: 13px;
            font-size: 17px;
        }
    }

    .page-sculptor {
        display: flex;
        flex-direction: column;
        width: 700px;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .white-bg {
        background-color: #fff;
        padding: 30px;
        height: fit-content;

        @media (max-width: 768px) {
            padding: 15px;
        }
    }

    .step-number {
        color: #4f4f4f;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .buttons {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
    }

    .stepper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
    }

    .dash {
        width: 20px;
        height: 2px;
        background-color: #d9d9d9;
        margin: 0 5px;

        @media (max-width: 768px) {
            width: 14px;
        }

        @media (max-width: 576px) {
            width: 10px;
        }
    }

    .dashes {
        display: flex;
        align-items: center;
    }

    .active-dash {
        background-color: #004aad;
    }

    .ctrl-btn {
        width: 100px;
        height: 30px;
        border-radius: 10px;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize !important;
        letter-spacing: 0;
    }

    ::v-deep .v-btn.next {
        color: #ffff;
        background-color: #004aad !important;
    }

    ::v-deep .v-btn.back {
        color: #004aad;
        background-color: #ffff;
        border: 2px solid #004aad;
    }

    .top-section {
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            margin-bottom: 20px !important;
        }
    }
</style>